// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { cilAt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormCheck,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { get, post, patch } from 'src/services/http-service'
import {
  ENDPOINT_GET_REDEEM_CODES,
  ENDPOINT_POST_SEND_REFERRAL,
  ENDPOINT_PATCH_UPDATE_REFERRALS_SENT_STATUS,
} from 'src/variables'
import { object, string } from 'yup'
import QRCode from 'qrcode'

const RedeemCodes = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [redeemCodes, setRedeemCodes] = useState([])
  const [codeDetailsOpen, setCodeDetailsOpen] = useState(false)
  const [selectedCode, setSelectedCode] = useState()
  const [sendReferralRequestStatus, setSendReferralRequestStatus] = useState('not_sent')
  const [updateCodesStatus, setUpdateCodesStatus] = useState('')

  useEffect(() => {
    async function fetchCodes() {
      const res = await get({
        endpoint: ENDPOINT_GET_REDEEM_CODES,
        bearerToken: userToken,
      })
      const ordered = res.tokens.sort((a, b) => (a.id > b.id ? -1 : 1))
      setRedeemCodes(ordered)
    }

    fetchCodes()
  }, [])

  useEffect(() => {
    if (codeDetailsOpen) {
      setSendReferralRequestStatus('not_sent')
    }
  }, [codeDetailsOpen])

  const resolverSchema = object({
    email: string()
      .required(`L'email é un campo obbligatorio`)
      .email(`L'email deve essere nel formato corretto`),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) })

  const submit = async (e) => {
    setSendReferralRequestStatus('pending')
    try {
      const res = await post({
        endpoint: ENDPOINT_POST_SEND_REFERRAL,
        bearerToken: userToken,
        data: {
          tokenValue: selectedCode.value,
          recipient: e.email,
        },
      })
      setSendReferralRequestStatus('success')
    } catch (err) {
      setSendReferralRequestStatus('failure')
    }
  }

  const updateCodes = (code, e) => {
    if (updateCodesStatus !== 'loading') {
      let newCodes = []
      redeemCodes.forEach((el) => {
        el.selected = el.value === code.value ? e : el.selected
        newCodes.push(el)
      })
      setRedeemCodes(newCodes)
    }
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const updateSentStatus = async (status) => {
    if (updateCodesStatus !== 'loading') {
      setUpdateCodesStatus('loading')
      try {
        await patch({
          endpoint: ENDPOINT_PATCH_UPDATE_REFERRALS_SENT_STATUS,
          bearerToken: userToken,
          data: {
            codes: redeemCodes.filter((el) => el.selected === true).map((elm) => elm.value),
            status: status,
          },
        })
        setUpdateCodesStatus('')
        let newCodes = []
        redeemCodes.forEach((el) => {
          el.sent = el.selected === true ? status : el.sent
          el.selected = false
          newCodes.push(el)
        })
        setRedeemCodes(newCodes)
        Array.from(document.querySelectorAll('.table input[type="checkbox"]')).forEach((el) => {
          el.checked = false
        })
      } catch (err) {
        alert(err)
      }
    }
  }

  const downloadQrCodes = async () => {
    if (updateCodesStatus !== 'loading') {
      setUpdateCodesStatus('loading')
      for (const code of redeemCodes.filter((el) => el.selected)) {
        await delay(500)
        const unlockUrl = `https://api.zonzoapp.com/discountToken/unlock/${code.value}`
        const qrCode = await QRCode.toDataURL(unlockUrl, {
          width: 1000,
          type: 'image/png',
          color: {
            dark: '#000000',
            light: '#fada16',
          },
        })
        var a = document.createElement('a')
        a.href = qrCode
        a.download = code.value + '.png'
        a.click()
      }
      setUpdateCodesStatus('')
    }
  }

  return (
    <>
      <CButton
        className="w-100 mb-3"
        disabled={
          redeemCodes.filter((el) => el.selected).length === 0 || updateCodesStatus === 'loading'
        }
        onClick={() => {
          updateSentStatus(true)
        }}
      >
        {updateCodesStatus === 'loading' ? (
          <CSpinner style={{ scale: '.8', width: '1.2rem', height: '1.2rem' }} />
        ) : (
          'Segna i codici selezionati come inviati'
        )}
      </CButton>
      <CButton
        className="w-100 mb-3"
        disabled={
          redeemCodes.filter((el) => el.selected).length === 0 || updateCodesStatus === 'loading'
        }
        onClick={() => {
          updateSentStatus(false)
        }}
      >
        {updateCodesStatus === 'loading' ? (
          <CSpinner style={{ scale: '.8', width: '1.2rem', height: '1.2rem' }} />
        ) : (
          'Segna i codici selezionati come non inviati'
        )}
      </CButton>
      <CButton
        className="w-100 mb-3"
        disabled={
          redeemCodes.filter((el) => el.selected).length === 0 || updateCodesStatus === 'loading'
        }
        onClick={() => {
          downloadQrCodes()
        }}
      >
        {updateCodesStatus === 'loading' ? (
          <CSpinner style={{ scale: '.8', width: '1.2rem', height: '1.2rem' }} />
        ) : (
          <span>
            Scarica i codici selezionati
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-download ms-2"
              viewBox="0 0 18 18"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </span>
        )}
      </CButton>
      <div style={{ overflow: 'auto' }}>
        <CTable striped hover bordered color="light">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">#</CTableHeaderCell>
              <CTableHeaderCell scope="col">Valore</CTableHeaderCell>
              <CTableHeaderCell scope="col">Creato il</CTableHeaderCell>
              <CTableHeaderCell scope="col">Disponibile</CTableHeaderCell>
              <CTableHeaderCell scope="col">Inviato</CTableHeaderCell>
              <CTableHeaderCell scope="col"></CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {redeemCodes.map((code, index) => (
              <CTableRow
                key={index}
                color={code.consumed ? 'danger' : undefined}
                onClick={(event) => {
                  if (!code.consumed && event.target.checked === undefined) {
                    setSelectedCode(code)
                    setCodeDetailsOpen(true)
                  }
                }}
              >
                <CTableHeaderCell scope="row">{code.id}</CTableHeaderCell>
                <CTableDataCell>{code.value}</CTableDataCell>
                <CTableDataCell>
                  {code.createdAt ? new Date(code.createdAt).toLocaleString('it') : '-'}
                </CTableDataCell>
                <CTableDataCell>{!code.consumed ? 'SÍ' : 'NO'}</CTableDataCell>
                <CTableDataCell>{code.sent ? 'SÍ' : code.consumed ? 'SÍ' : 'NO'}</CTableDataCell>
                <CTableDataCell>
                  <CFormCheck
                    style={{ cursor: 'pointer', width: '1.2rem', height: '1.2rem' }}
                    disabled={!code.consumed ? false : true}
                    defaultValue={code.selected}
                    onChange={(e) => updateCodes(code, e.target.checked)}
                  />
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </div>
      {selectedCode && (
        <CModal
          keyboard={false}
          visible={codeDetailsOpen}
          onClose={() => setCodeDetailsOpen(false)}
          alignment="center"
        >
          <CModalHeader>
            <CModalTitle>Dettagli</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <CRow>
                <CCol sm={3}>Valore:</CCol>
                <CCol sm={9}>{selectedCode.value}</CCol>
              </CRow>
              <CRow>
                <CCol sm={3}>Disponibile:</CCol>
                <CCol sm={9}>{!selectedCode.consumed ? 'SÍ' : 'NO'}</CCol>
              </CRow>
              {/* <CRow className="justify-content-center">
                <CCol sm="auto">
                  <QRCode level="M" value={selectedCode.value} />
                </CCol>
              </CRow> */}
              <CRow className="mt-3">
                <CCol sm={12}>
                  <h3>Invia QR Code</h3>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}>
                  <CForm onSubmit={handleSubmit(submit)}>
                    <CInputGroup>
                      <CInputGroupText>
                        <CIcon icon={cilAt} />
                      </CInputGroupText>

                      <Controller
                        render={({ field }) => {
                          return (
                            <>
                              <CFormInput
                                placeholder="Email"
                                autoComplete="email"
                                onChange={field.onChange}
                              />
                              <CCol xs={12}>
                                <span>{errors[field.name]?.message}</span>
                              </CCol>
                            </>
                          )
                        }}
                        name="email"
                        control={control}
                      />
                    </CInputGroup>
                    {sendReferralRequestStatus === 'not_sent' && (
                      <CButton type="submit" className="w-50 mt-3 mx-auto d-block">
                        Invia
                      </CButton>
                    )}
                    {sendReferralRequestStatus === 'pending' && (
                      <div className="w-100 mt-3 d-flex justify-content-center">
                        <CSpinner />
                      </div>
                    )}
                    {sendReferralRequestStatus === 'success' && (
                      <p className="w-100 mt-3 text-center">Codice inviato con successo</p>
                    )}
                    {sendReferralRequestStatus === 'failure' && (
                      <p className="w-100 mt-3 text-center">{`Errore durante l'invio del codice, chiudi il pannello e riprova`}</p>
                    )}
                  </CForm>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}></CCol>
              </CRow>
            </CContainer>
          </CModalBody>
        </CModal>
      )}
    </>
  )
}

export default RedeemCodes
